import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';

import { AlertService, AuthService } from '../../services';

let isAccountDisabledAlertShown = false;

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService: AuthService = inject(AuthService);
  const alertService: AlertService = inject(AlertService);
  const router = inject(Router);

  return next(req).pipe(catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          if (err.error.message === 'Error token') {
            authService.disconnectLocally();
          }
        } else if (err.status === 403) {
          alertService.presentForbiddenHttpRequestAlert()
            .then(() => router.navigate(['/']));
        } else if (err.status === 423) {
          authService.isLoggedIn().then((authenticated) => {
            if (authenticated) {
              authService.disconnectLocally();
            }
            if (!isAccountDisabledAlertShown) {
              isAccountDisabledAlertShown = true;
              alertService.presentAccountDisabledAlert()
                .then(() => {
                  setTimeout(() => {
                    isAccountDisabledAlertShown = false;
                  }, 1000);
                });
            }
          });
        } else if (err.status === 426) {
          router.navigate(['update-app']);
        } else if (err.status === 503) {
          router.navigate(['maintenance']);
        } else {
          console.error('HTTP error:', err);
        }
      } else {
        console.error('An error occurred:', err);
      }

      return throwError(() => err);
    })
  );
};
